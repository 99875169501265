import "goi_common/src/styles/globals.css";

import { HStack, NextLink, colors } from "goi_common";
import styled from "@emotion/styled";

export default function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <BackgroundContainer>
      <Container>
        <Header
          css={{
            padding: "8px 20px 10px",
            position: "sticky",
          }}
        >
          <NextLink href="/">
            <HStack alignItems="center" gap="9px">
              <img
                src="/heysangjo_face.png"
                css={{
                  width: "40px",
                }}
                alt="heysangjo logo"
              />
              <span className="white subtitle_18_b">헤이상조</span>
            </HStack>
          </NextLink>
        </Header>
        <main className="white">{children}</main>
      </Container>
    </BackgroundContainer>
  );
}

const BackgroundContainer = styled.div`
  background-color: #1b1b1b;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  background-color: ${colors.gray900};
  max-width: 480px;
  width: 100%;
`;

const Header = styled.header`
  padding: "8px 20px 10px";
  position: sticky;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
`;
