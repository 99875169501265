const title = "상조서비스 비교, 가전결합상품 비교는 헤이상조.";

export const system = {
  defaultMetaData: {
    pageDescription:
      "상조 서비스, 가전 결합상품, 장례식장 비용까지 합리적이면서 믿을 수 있는 서비스를 알아보세요",
    pageKeywords: "상조, 상조비교, 선불제상조, 후불제상조, 프리드라이프, 보람상조, 대명아임레디,",
    pageOgTitle: title,
    pageOgDescription:
      "상조 서비스, 가전 결합상품, 장례식장 비용까지 합리적이면서 믿을 수 있는 서비스를 알아보세요",
    pageOgImage: "",
  },
};
