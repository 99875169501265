import { system } from "@/config/layout";
import { HackleProvider } from "@hackler/react-sdk";
import { ThemeProvider } from "@mui/material";
import { OverlayProvider } from "@toss/use-overlay";
import { GlobalStyle, HackleClient, muiTheme, useTrackingGoiAnchorTag } from "goi_common";
import { AppProps } from "next/app";
import Head from "next/head";
import Layout from "../components/Layout";
import "@/styles/reset.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { GTM_ID } from "@/constants/gtag";
import Script from "next/script";

const { defaultMetaData } = system;

const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}')`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const App = ({ Component, pageProps }: AppProps) => {
  /** GA 크로스도메인 연동 */
  useTrackingGoiAnchorTag();

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>{pageProps.pageOgTitle ? pageProps.pageOgTitle : defaultMetaData.pageOgTitle}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      {process.env.NODE_ENV === "production" && (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: GTM_SCRIPT }}
        />
      )}
      <ThemeProvider theme={muiTheme}>
        <GlobalStyle />
        <OverlayProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </OverlayProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
